import { useSearchParams } from "react-router-dom";
import { useMemo, useEffect } from "react";

export default function Filter({ cars, closeFilter }) {
  const [params, setParams] = useSearchParams();

  // Фиксиран ред на моделите
  const modelOrder = [
    "Fiesta",
    "Puma",
    "Focus",
    "New Kuga",
    "Kuga",
    "Mustang",
    "E-Explorer",
    "Capri",
    "Mach-E",
    "Explorer",
    "Bronco",
    "Ranger",
    "Courier",
    "Connect",
    "Tourneo Connect",
    "New Custom",
    "Transit",
  ];

  // Изчисляване на списъците с уникални стойности за филтрите и броя им
  const models = useMemo(() => {
    const modelCounts = cars.reduce((acc, car) => {
      const modelUpperCase = car.model.toUpperCase(); // Преобразуване на моделите към главни букви
      acc[modelUpperCase] = (acc[modelUpperCase] || 0) + 1;
      return acc;
    }, {});

    return modelOrder
      .map((model) => ({
        name: model,
        count: modelCounts[model.toUpperCase()] || 0, // Сравняване на моделите в главни букви
      }))
      .filter((model) => model.count > 0); // Показваме само модели с налични автомобили
  }, [cars]);

  const fuels = useMemo(
    () => [...new Set(cars.map((car) => car.fuel))],
    [cars]
  );
  const gearboxes = useMemo(
    () => [...new Set(cars.map((car) => car.gearbox))],
    [cars]
  );
  const vats = useMemo(() => [...new Set(cars.map((car) => car.vat))], [cars]);

  const modelsList = params.getAll("model");
  const fuelsList = params.getAll("fuel");
  const gearboxesList = params.getAll("gearbox");
  const vatsList = params.getAll("vat");

  // Обновяване на състоянието на checkbox-овете при промяна на параметрите
  useEffect(() => {
    document.querySelectorAll("input").forEach((item) => {
      item.checked =
        modelsList.includes(item.value) ||
        fuelsList.includes(item.value) ||
        gearboxesList.includes(item.value) ||
        vatsList.includes(item.value);
    });
  }, [modelsList, fuelsList, gearboxesList, vatsList]);

  // Универсална функция за обработка на промените във филтрите
  function handleFilterChange(e, filterType) {
    const chosenValue = e.target.value;
    const filterList = params.getAll(filterType);
    if (filterList.includes(chosenValue)) {
      const updatedList = filterList.filter((item) => item !== chosenValue);
      params.delete(filterType);
      updatedList.forEach((item) => params.append(filterType, item));
    } else {
      params.append(filterType, chosenValue);
    }
    setParams(params);
  }

  // Функция за изчистване на всички филтри
  function clearFilter() {
    ["model", "fuel", "gearbox", "vat"].forEach((filterType) => {
      params.delete(filterType);
    });
    setParams(params);
  }

  return (
    <div className="sticky top-0 p-4">
      <h2 className="text-2xl">Филтър</h2>

      <div className="p-2 pb-4 mt-8 border-b">
        <h3 className="uppercase">Модел</h3>
        {models.map(({ name, count }) => (
          <div key={name} className="flex items-center mt-2">
            <input
              type="checkbox"
              id={name}
              className="w-5 h-5 focus:outline-none focus:ring-0 text-fordBlue"
              onChange={(e) => handleFilterChange(e, "model")}
              value={name}
            />
            <label htmlFor={name} className="pl-2 uppercase">
              {name} <span className="text-gray-400">({count})</span>
            </label>
          </div>
        ))}
      </div>

      <div className="p-2 pb-4 mt-2 border-b">
        <h3 className="uppercase">Гориво</h3>
        {fuels.map((fuel) => (
          <div key={fuel} className="flex items-center mt-2">
            <input
              type="checkbox"
              className="w-5 h-5 focus:outline-none focus:ring-0 text-fordBlue"
              id={fuel}
              onChange={(e) => handleFilterChange(e, "fuel")}
              value={fuel}
            />
            <label htmlFor={fuel} className="pl-2 capitalize">
              {fuel}
            </label>
          </div>
        ))}
      </div>

      <div className="p-2 pb-4 mt-2 border-b">
        <h3 className="uppercase">Скоростна кутия</h3>
        {gearboxes.map((gearbox) => (
          <div key={gearbox} className="flex items-center mt-2">
            <input
              type="checkbox"
              id={gearbox}
              className="w-5 h-5 focus:outline-none focus:ring-0 text-fordBlue"
              onChange={(e) => handleFilterChange(e, "gearbox")}
              value={gearbox}
            />
            <label htmlFor={gearbox} className="pl-2 capitalize">
              {gearbox}
            </label>
          </div>
        ))}
      </div>

      <div className="p-2 pb-4 mt-2 border-b">
        <h3 className="uppercase">Право на данъчен кредит</h3>
        {vats.map((vat) => (
          <div key={vat} className="flex items-center mt-2">
            <input
              type="checkbox"
              id={vat}
              className="w-5 h-5 focus:outline-none focus:ring-0 text-fordBlue"
              onChange={(e) => handleFilterChange(e, "vat")}
              value={vat}
            />
            <label htmlFor={vat} className="pl-2 capitalize">
              {vat}
            </label>
          </div>
        ))}
      </div>

      <button className="mt-8 btn-primary lg:hidden" onClick={closeFilter}>
        Приложи филтрите
      </button>
      <button
        className="mt-4 btn-secondary lg:btn-primary"
        onClick={clearFilter}
      >
        Изчисти филтрите
      </button>
    </div>
  );
}
