import { Link, useLoaderData } from "react-router-dom";

import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

function Hotlist() {
  const hotlistName = "Горещи летни оферти";
  document.title = "FORD - " + hotlistName;

  const hotlist = [
    "VEH170811",
    "VEH170808",
    "VEH173533",
    "VEH173525",
    "VEH173537",
    "VEH173552",
    "VEH173569",
    "VEH173536",
    "VEH173539",
    "VEH173534",
    "VEH173554",
    "VEH173584",
    "VEH173556",
    "VEH173560",
    "VEH173535",
    "VEH173550",
    "VEH173576",
    "VEH173531",
    "VEH173572",
    "VEH173538",
    "VEH173558",
    "VEH171578",
    "VEH171573",
    "VEH173463",
    "VEH173459",
    "VEH173469",
    "VEH171585",
    "VEH173467",
    "VEH173462",
    "VEH173471",
    "VEH171547",
    "VEH174152",
    "VEH168742",
    "VEH174985",
    "VEH173771",
    "VEH173772",
    "VEH173770",
    "VEH173773",
    "VEH173195",
    "VEH172207",
    "VEH173816",
    "VEH170332",
    "VEH169773",
    "VEH169776",
    "VEH168626",
    "VEH176754",
    "VEH176751",
    "VEH173598",
    "VEH173596",
    "VEH173597",
    "VEH166233",
    "VEH169464",
    "VEH169462",
    "VEH169463",
    "VEH174093",
    "VEH174118",
    "VEH174121",
    "VEH174108",
    "VEH174067",
    "VEH174072",
    "VEH174110",
    "VEH174107",
    "VEH174111",
    "VEH174119",
    "VEH179081",
    "VEH179529",
    "VEH179530",
    "VEH179536",
    "VEH178614",
    "VEH179077",
    "VEH181202",
  ];

  const cars = useLoaderData();
  cars.sort((a, b) => a.new_price - b.new_price);

  function convertCyrillicToLatin(text) {
    text = text.normalize("NFD"); // Нормализиране на текста
    return text
      .replaceAll("й", "i")
      .replaceAll("Й", "I")
      .replaceAll("ц", "ts")
      .replaceAll("Ц", "Ts")
      .replaceAll("у", "u")
      .replaceAll("У", "U")
      .replaceAll("к", "k")
      .replaceAll("К", "K")
      .replaceAll("е", "e")
      .replaceAll("Е", "E")
      .replaceAll("н", "n")
      .replaceAll("Н", "N")
      .replaceAll("г", "g")
      .replaceAll("Г", "G")
      .replaceAll("ш", "sh")
      .replaceAll("Ш", "Sh")
      .replaceAll("щ", "sht")
      .replaceAll("Щ", "Sht")
      .replaceAll("з", "z")
      .replaceAll("З", "Z")
      .replaceAll("х", "h")
      .replaceAll("Х", "H")
      .replaceAll("ъ", "a")
      .replaceAll("Ъ", "A")
      .replaceAll("ф", "f")
      .replaceAll("Ф", "F")
      .replaceAll("в", "v")
      .replaceAll("В", "V")
      .replaceAll("а", "a")
      .replaceAll("А", "A")
      .replaceAll("п", "p")
      .replaceAll("П", "P")
      .replaceAll("р", "r")
      .replaceAll("Р", "R")
      .replaceAll("о", "o")
      .replaceAll("О", "O")
      .replaceAll("т", "t")
      .replaceAll("Т", "T")
      .replaceAll("л", "l")
      .replaceAll("Л", "L")
      .replaceAll("д", "d")
      .replaceAll("Д", "D")
      .replaceAll("и", "i")
      .replaceAll("И", "I")
      .replaceAll("ж", "zh")
      .replaceAll("Ж", "Zh")
      .replaceAll("я", "ya")
      .replaceAll("Я", "Ya")
      .replaceAll("ч", "ch")
      .replaceAll("Ч", "Ch")
      .replaceAll("с", "s")
      .replaceAll("С", "S")
      .replaceAll("м", "m")
      .replaceAll("М", "M")
      .replaceAll("б", "b")
      .replaceAll("Б", "B")
      .replaceAll("ю", "yu")
      .replaceAll("Ю", "Yu");
  }

  const generateImageURL = (car) => {
    return `../images/${convertCyrillicToLatin(car.model)
      .toLowerCase()
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/\//g, "")}-${convertCyrillicToLatin(car.body)
      .toLowerCase()
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/\//g, "")}-${convertCyrillicToLatin(car.trim)
      .toLowerCase()
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/\//g, "")}-${convertCyrillicToLatin(car.color)
      .toLowerCase()
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/\//g, "")}.jpeg`;
  };

  return (
    <div>
      <div className="bg-[url('../public/images/chilling_offers.jpg')] bg-contain bg-no-repeat h-60 lg:bg-cover lg:h-[516px]">
        {/* <h1 className="my-16 text-2xl font-bold tracking-wide text-center text-white uppercase">
          {hotlistName}
        </h1> */}
      </div>
      <div className="container grid grid-cols-1 gap-4 px-4 md:grid-cols-2 lg:grid-cols-4">
        {/* <div className="block">{cars && <Filter cars={cars} />}</div> */}
        {cars.map((car) => {
          if (hotlist.includes(car.id)) {
            const imageURL = generateImageURL(car);

            let discount =
              ((car.old_price - car.new_price) / car.old_price) * 100;

            return (
              <div
                layout
                className="p-2 mt-4 shadow-md item hover:shadow-lg"
                key={car.id}
                id="the-list"
              >
                <div className="flex items-start justify-between">
                  <div className="min-h-[92px]">
                    <h3 className="mt-2 text-2xl uppercase text-fordBlue">
                      {car.model}
                    </h3>
                    <p className="text-sm text-gray-500">Оферта {car.id}</p>
                  </div>
                  {discount !== 0 && (
                    <div className="flex flex-col px-4 py-2  text-center rounded-sm bg-fordRed min-w-[180px]">
                      <p className="text-sm tracking-widest text-white uppercase">
                        Ексклузивна оферта
                      </p>
                      <p className="text-sm tracking-widest text-center text-white uppercase ">
                        -
                        {new Intl.NumberFormat("bg", {
                          maximumFractionDigits: 0,
                        }).format(discount)}
                        %
                      </p>
                    </div>
                  )}
                </div>
                <img src={imageURL} alt="" />
                <div className="flex justify-between">
                  <p className="text-gray-500">Гориво</p>
                  <p className="text-fordBlue">{car.fuel}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Скоростна кутия</p>
                  <p className="text-fordBlue">{car.gearbox}</p>
                </div>
                <p className="p-4 mt-4 text-xl text-center bg-gray-50">
                  Цена:{" "}
                  {new Intl.NumberFormat("bg", {
                    minimumFractionDigits: 0,
                  }).format(car.new_price)}{" "}
                  <span className="text-sm">лв. с ДДС</span>
                </p>
                <div className="mx-auto text-center">
                  <Link to={`/${car.id}`}>
                    <button className="mt-4 mb-2 border btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                      Виж още
                    </button>
                  </Link>
                </div>
              </div>
            );
          } else {
            return;
          }
        })}
      </div>
    </div>
  );
}

export default Hotlist;

export const carsLoader = async () => {
  let results = [];
  const querySnapshot = await getDocs(collection(db, "cars"));
  querySnapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });

  // Сортиране във възходящ ред по нова цена
  results.sort((a, b) => b.new_price - a.new_price);

  return results;
};
