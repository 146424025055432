import { Link, NavLink, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Suspense, useEffect, useState } from "react";

const hotlist = [
  "VEH170811",
  "VEH170808",
  "VEH173533",
  "VEH173525",
  "VEH173537",
  "VEH173552",
  "VEH173569",
  "VEH173536",
  "VEH173539",
  "VEH173534",
  "VEH173554",
  "VEH173584",
  "VEH173556",
  "VEH173560",
  "VEH173535",
  "VEH173550",
  "VEH173576",
  "VEH173531",
  "VEH173572",
  "VEH173538",
  "VEH173558",
  "VEH171578",
  "VEH171573",
  "VEH173463",
  "VEH173459",
  "VEH173469",
  "VEH171585",
  "VEH173467",
  "VEH173462",
  "VEH173471",
  "VEH171547",
  "VEH174152",
  "VEH168742",
  "VEH174985",
  "VEH173771",
  "VEH173772",
  "VEH173770",
  "VEH173773",
  "VEH173195",
  "VEH172207",
  "VEH173816",
  "VEH170332",
  "VEH169773",
  "VEH169776",
  "VEH168626",
  "VEH176754",
  "VEH176751",
  "VEH173598",
  "VEH173596",
  "VEH173597",
  "VEH166233",
  "VEH169464",
  "VEH169462",
  "VEH169463",
  "VEH174093",
  "VEH174118",
  "VEH174121",
  "VEH174108",
  "VEH174067",
  "VEH174072",
  "VEH174110",
  "VEH174107",
  "VEH174111",
  "VEH174119",
  "VEH179081",
  "VEH179529",
  "VEH179530",
  "VEH179536",
  "VEH178614",
  "VEH179077",
  "VEH181202",
];

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

// Специални ID-та на автомобилите
const specialIds = new Set([
  "VEH172205",
  "VEH172207",
  "VEH173195",
  "VEH173813",
  "VEH173816",
  "VEH173770",
  "VEH173771",
  "VEH173772",
  "VEH173773",
  "VEH172211",
]);

// Функция за конвертиране на кирилица в латиница
function convertCyrillicToLatin(text) {
  text = text.normalize("NFD"); // Нормализиране на текста
  return text
    .replaceAll("й", "i")
    .replaceAll("Й", "I")
    .replaceAll("ц", "ts")
    .replaceAll("Ц", "Ts")
    .replaceAll("у", "u")
    .replaceAll("У", "U")
    .replaceAll("к", "k")
    .replaceAll("К", "K")
    .replaceAll("е", "e")
    .replaceAll("Е", "E")
    .replaceAll("н", "n")
    .replaceAll("Н", "N")
    .replaceAll("г", "g")
    .replaceAll("Г", "G")
    .replaceAll("ш", "sh")
    .replaceAll("Ш", "Sh")
    .replaceAll("щ", "sht")
    .replaceAll("Щ", "Sht")
    .replaceAll("з", "z")
    .replaceAll("З", "Z")
    .replaceAll("х", "h")
    .replaceAll("Х", "H")
    .replaceAll("ъ", "a")
    .replaceAll("Ъ", "A")
    .replaceAll("ф", "f")
    .replaceAll("Ф", "F")
    .replaceAll("в", "v")
    .replaceAll("В", "V")
    .replaceAll("а", "a")
    .replaceAll("А", "A")
    .replaceAll("п", "p")
    .replaceAll("П", "P")
    .replaceAll("р", "r")
    .replaceAll("Р", "R")
    .replaceAll("о", "o")
    .replaceAll("О", "O")
    .replaceAll("т", "t")
    .replaceAll("Т", "T")
    .replaceAll("л", "l")
    .replaceAll("Л", "L")
    .replaceAll("й", "i")
    .replaceAll("Й", "I") // 'й' (Cyrillic Small Letter I with Breve)
    .replaceAll("д", "d")
    .replaceAll("Д", "D") // 'д' (Cyrillic Small Letter D)
    .replaceAll("\u0434", "d")
    .replaceAll("\u0414", "D") // 'д' (Cyrillic Small Letter D)
    .replaceAll("\u0442", "t")
    .replaceAll("\u0422", "T") // 'т' (Cyrillic Small Letter Te)
    .replaceAll("\u0442\u0301", "t") // 'т́' (Cyrillic Small Letter Te with Acute)
    .replaceAll("\u0442\u0327", "t") // 'т̧' (Cyrillic Small Letter Te with Dot Above)
    .replaceAll("\u0442\u0307", "t") // 'т̇' (Cyrillic Small Letter Te with Dot Below)
    .replaceAll("\u0438\u0301", "i") // 'ĭ' (Latin Small Letter I with Breve)
    .replaceAll("\u0438\u0327", "i") // 'i̇' (Latin Small Letter I with Dot Above)
    .replaceAll("\u0456", "i") // 'і' (Cyrillic Small Letter Byelorussian-Ukrainian I)
    .replaceAll("\u0457", "i") // 'ї' (Cyrillic Small Letter Yi)
    .replaceAll("\u0131", "i") // 'ı' (Latin Small Letter Dotless I)
    .replaceAll("\u0458", "i") // 'ј' (Cyrillic Small Letter Je)
    .replaceAll("и", "i")
    .replaceAll("И", "I") // Стандартно 'и'
    .replaceAll("ж", "zh")
    .replaceAll("Ж", "Zh")
    .replaceAll("я", "ya")
    .replaceAll("Я", "Ya")
    .replaceAll("ч", "ch")
    .replaceAll("Ч", "Ch")
    .replaceAll("с", "s")
    .replaceAll("С", "S")
    .replaceAll("м", "m")
    .replaceAll("М", "M")
    .replaceAll("б", "b")
    .replaceAll("Б", "B")
    .replaceAll("ю", "yu")
    .replaceAll("Ю", "Yu")
    .replaceAll("\u0425", "h")
    .replaceAll("\u0425", "H") // 'Х' (Cyrillic Capital Letter HA)
    .replaceAll("\u0438", "i")
    .replaceAll("\u0418", "I"); // 'и' (Cyrillic Small Letter I)
}

// Функция за генериране на URL на изображение
const generateImageURL = (car) => {
  const imageURL = `../images/${convertCyrillicToLatin(car.model)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.body)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.trim)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.color)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}.jpeg`;

  console.log(imageURL); // Добави това
  return imageURL;
};

// Функция за обработка на грешки при зареждане на изображения
const onImageError = (e) => {
  e.target.src = "../images/default-car.jpg";
};

// Основен компонент
export default function CarList({ cars }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNoCarsMessage, setShowNoCarsMessage] = useState(false);

  // Получаване на параметри от URL
  const getParams = (param) =>
    searchParams.getAll(param).map((p) => p.toUpperCase()) || [];

  const modelsList = getParams("model");
  const fuelsList = getParams("fuel");
  const gearboxesList = getParams("gearbox");
  const vatsList = getParams("vat");

  // Филтриране на автомобили
  const filteredCars = cars.filter(
    (car) =>
      (modelsList.length === 0 ||
        modelsList.includes(car.model.toUpperCase())) &&
      (fuelsList.length === 0 || fuelsList.includes(car.fuel.toUpperCase())) &&
      (gearboxesList.length === 0 ||
        gearboxesList.includes(car.gearbox.toUpperCase())) &&
      (vatsList.length === 0 || vatsList.includes(car.vat.toUpperCase()))
  );

  //   const sortedCars = [...filteredCars].sort(
  //     (a, b) => a.new_price - b.new_price
  //   );

  // Сортиране по отстъпка
  const sortedCars = [...filteredCars].sort((a, b) => {
    const discountA = ((a.old_price - a.new_price) / a.old_price) * 100;
    const discountB = ((b.old_price - b.new_price) / b.old_price) * 100;

    // Ако и двата автомобила са в hotlist, сортирай по отстъпка
    if (hotlist.includes(a.id) && hotlist.includes(b.id)) {
      return discountB - discountA;
    }

    // Ако само единият е в hotlist, той трябва да е по-напред
    if (hotlist.includes(a.id)) return -1;
    if (hotlist.includes(b.id)) return 1;

    // Ако нито един не е в hotlist, сортирай по отстъпка
    return discountB - discountA;
  });

  // Генериране на налични модели
  // const availableModels = [...new Set(cars.map((car) => car.model))];

  // Изчистване на URL параметрите след 5 секунди, ако няма налични автомобили
  useEffect(() => {
    if (filteredCars.length === 0) {
      setShowNoCarsMessage(true);

      const timer = setTimeout(() => {
        setSearchParams({});
      }, 5000); // Изчистване след 5 секунди

      return () => clearTimeout(timer); // Почистете таймера при размонтиране
    } else {
      setShowNoCarsMessage(false);
    }
  }, [filteredCars.length, setSearchParams]);

  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
      >
        <NavLink to="/landing/Courier30990" className="cursor-pointer">
          <div
            className="relative w-full bg-center bg-contain aspect-[768/950] md:aspect-[1438/516] 
                md:bg-[url('https://sale.ford.bg/images/courier30990/courier30990.jpg')] 
                bg-[url('https://sale.ford.bg/images/courier30990/courier30990-mobile.jpg')]"
          >
            <p className="absolute w-full text-4xl text-center  text-white transform -translate-x-1/2 font-fordBlack left-1/2 drop-shadow-md top-[4%] md:top-[4%]">
              НОВИЯТ FORD COURIER
            </p>
            <p className="absolute w-full top-[23%] md:top-[13%] text-lg md:text-2xl text-white text-center transform -translate-x-1/2 left-1/2 font-fordMedium">
              Сега на специална цена от 30 990 лв.*
            </p>
            <button className="absolute bottom-[16%] md:bottom-[12%] transform -translate-x-1/2 left-1/2 px-4 py-2 text-sm tracking-wider text-white border bg-fordBlue btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
              НАУЧЕТЕ ПОВЕЧЕ
            </button>
            <p className="w-full absolute bottom-[8%] md:bottom-[7%] text-[10px] text-white text-center transform -translate-x-1/2 left-1/2 font-fordRegular">
              *ЦЕНАТА Е БЕЗ ДДС И Е ВАЛИДНА ЗА FORD TRANSIT COURIER VAN БЕНЗИН,
              100 К.С., 6-СТЕПЕННА МЕХАНИЧНА ТРАНСМИСИЯ, САМО ЗА ЮРИДИЧЕСКИ
              ЛИЦА.
            </p>
          </div>
        </NavLink>
        <div className="relative">
          <div
            className="relative w-full bg-center bg-contain aspect-[768/950]  md:aspect-[1438/516] 
                md:bg-[url('https://sale.ford.bg/images/puma-gen-e-desktop-vignette.jpg')] 
                bg-[url('https://sale.ford.bg/images/puma-gen-e-mobile.jpg')]"
          >
            <h2 className="absolute text-2xl text-center md:text-left text-white tracking-wider ml-6 md:ml-2 md:left-8 font-fordMedium  top-[60%] md:top-[12%]">
              Избери своя FORD PUMA<sup>®</sup>
            </h2>
            <div className="absolute flex flex-wrap flex-col md:flex-row  md:justify-start w-full gap-2 transform top-[70%] md:top-[21%] md:left-8">
              <a
                href="https://ford.bg/cars/puma-gen-e"
                rel="noreferrer"
                target="_blank"
              >
                <button className="px-4 py-2 text-xs tracking-widest text-white uppercase border bg-fordBlue btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                  Електрически модел
                </button>
              </a>
              <a
                href="https://ford.bg/cars/new-puma"
                rel="noreferrer"
                target="_blank"
              >
                <button className="px-4 py-2 text-xs tracking-widest text-white uppercase border bg-fordBlue btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                  Хибриден модел
                </button>
              </a>
            </div>
          </div>
        </div>
        <NavLink
          to="/landing/electric-explorer-free-charging"
          className="cursor-pointer"
        >
          <div
            className="relative w-full bg-center bg-contain aspect-[768/950] md:aspect-[1438/516] 
                md:bg-[url('https://sale.ford.bg/images/electric-explorer-free-charging/explorer.jpg')] 
                bg-[url('https://sale.ford.bg/images/electric-explorer-free-charging/explorer-mobile.jpg')]"
          >
            <p className="absolute text-3xl text-center text-white transform -translate-x-1/2 font-fordMedium left-1/2 drop-shadow-md top-[10%] md:top-[15%]">
              NEW ALL ELECTRIC EXPLORER{" "}
              <span className="text-sm align-super">®</span>
            </p>
          </div>
        </NavLink>
      </Carousel>

      <motion.div
        layout
        className="container grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {sortedCars.length > 0 ? (
          sortedCars.map((car) => {
            const imageURL = specialIds.has(car.id)
              ? generateImageURL(car).replace(".jpeg", "-copy.jpeg")
              : generateImageURL(car);

            const discount =
              ((car.old_price - car.new_price) / car.old_price) * 100;

            return (
              <motion.div
                layout
                className="p-2 mt-4 shadow-md item hover:shadow-lg"
                variants={item}
                key={car.id}
                id="the-list"
              >
                <div className="flex items-start justify-between">
                  <div className="min-h-[92px]">
                    <h3 className="mt-2 text-2xl uppercase text-fordBlue">
                      {car.model}
                    </h3>
                    <p className="text-sm text-gray-500">Оферта {car.id}</p>
                  </div>
                  {discount !== 0 && (
                    <div
                      className={`flex flex-col px-4 py-2 text-center rounded-sm min-w-[180px] ${
                        hotlist.includes(car.id) ? "bg-fordRed" : "bg-fordLight"
                      }`}
                    >
                      <p className="text-sm tracking-widest text-white uppercase">
                        {hotlist.includes(car.id)
                          ? "Ексклузивна оферта"
                          : "Специална оферта"}
                      </p>
                      <p className="text-sm tracking-widest text-center text-white uppercase ">
                        -
                        {new Intl.NumberFormat("bg", {
                          maximumFractionDigits: 0,
                        }).format(discount)}
                        %
                      </p>
                    </div>
                  )}
                </div>
                <Suspense fallback={<div>Зареждане...</div>}>
                  <img src={imageURL} alt={car.model} onError={onImageError} />
                </Suspense>
                <div className="flex justify-between">
                  <p className="text-gray-500">Гориво</p>
                  <p className="text-fordBlue">{car.fuel}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Скоростна кутия</p>
                  <p className="text-fordBlue">{car.gearbox}</p>
                </div>
                <p className="p-4 mt-4 text-xl text-center bg-gray-50">
                  Цена:{" "}
                  {new Intl.NumberFormat("bg", {
                    minimumFractionDigits: 0,
                  }).format(car.new_price)}{" "}
                  <span className="text-sm">лв. с ДДС</span>
                </p>
                <div className="mx-auto text-center">
                  <Link to={car.id}>
                    <button className="mt-4 mb-2 border btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                      Виж още
                    </button>
                  </Link>
                </div>
              </motion.div>
            );
          })
        ) : (
          <div className="py-8 mt-16 text-center border rounded-lg text-fordBlue col-span-full">
            <h2 className="text-2xl">
              В момента няма налични автомобили {modelsList}
            </h2>
          </div>
        )}
      </motion.div>
    </>
  );
}
