import Modal from "../components/Modal";
import { useState } from "react";
import { GetInTouch } from "../components/GetInTouch";

function Puma2024() {
  const model = "Puma 2024";
  const copy = "p.tabakova@motopfohe.bg";
  document.title = "Новият Ford Puma 2024";
  const [showModal, setShowModal] = useState(false);
  const [zapitvane, setZapitvane] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setZapitvane(false);
  };

  return (
    <div>
      <div className="relative w-full">
        <img
          src="/images/puma2024/puma.jpg" // Десктоп версията (по подразбиране)
          srcSet="
      /images/puma2024/puma-mobile.jpg 1024w,
      /images/puma2024/puma.jpg 1440w
    "
          sizes="(max-width: 1024px) 100vw, 1440px"
          alt="Новият Ford Puma 2024"
          className="w-full"
        />

        {/* Заглавие */}
        <h2 className="absolute w-full text-center px-4 text-2xl md:text-2xl font-fordBlack text-white drop-shadow-md left-1/2 top-[3%] transform -translate-x-1/2 uppercase">
          Новият Ford Puma
        </h2>
        <h1 className="absolute w-full text-center px-4 text-4xl md:text-4xl font-fordMedium text-white drop-shadow-md left-1/2 top-[10%] transform -translate-x-1/2">
          Сега на специална цена от 42 990лв.
        </h1>

        {/* Подзаглавие */}
        <p className="absolute text-4xl text-center font-fordMedium text-white drop-shadow-md left-1/2 top-[70%] md:top-[80%] transform -translate-x-1/2">
          За града, който обичаш и мразиш
        </p>
      </div>
      <p className="px-8 mt-16 text-xl text-center lg:px-72 text-fordBlue">
        Сега още по-сигурен и безопасен, още по-технологичен и още по-свързан!
        Компактен, динамичен и стилен, Ford Puma е създаден, за да се справя с
        всяко предизвикателство в градска среда. Изпитайте удоволствието от
        шофирането и усетете уникалното съчетание между спортен дух и модерен
        дизайн.
      </p>
      <div className="flex justify-center mt-8 text-center margin-auto">
        <button
          className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
          onClick={() => {
            setShowModal(true);
            setZapitvane(true);
            window.scrollTo(0, 0);
          }}
        >
          Поискай оферта
        </button>
      </div>
      <div className="relative mt-16">
        <img
          src="/images/puma2024/2024_FORD_PUMA_25-LOW.jpg"
          alt=""
          className="w-full"
        />
        <ul className="w-full px-6 py-8 text-lg text-white list-disc list-outside bg-fordBlue lg:bg-transparent lg:bg-gradient-to-b lg:from-black/10 lg:via-black/20 lg:to-black/80 lg:absolute lg:bottom-0 lg:grid lg:grid-cols-2 lg:px-10 lg:gap-x-8">
          <li className="mt-4">
            <span className="font-bold">Мощен и икономичен</span> – с последно
            поколение мека хибридна технология за по-нисък разход на гориво.
          </li>
          <li className="mt-4">
            <span className="font-bold">Смарт функции</span> – оборудван с
            най-новите технологии за свързаност и в помощ на в водача,
            включително адаптивен круиз контрол и 360-градусова камера.
          </li>
          <li className="mt-4">
            <span className="font-bold">Сигурен и безопасен</span> – Оборудван с
            най-усъвършенстваните технологии за сигурлност и безопасност,
            включително Асистент за предотвратяване на сблъсък и aвтоматично
            задействане на спирачките след инцидент; Асистент за следене на
            пътната лента; система за следене умората у водача и показателите на
            шофиране.
          </li>
          <li className="mt-4">
            <span className="font-bold">Стилен и практичен</span> – с гъвкаво
            багажно пространство от 456 литра и просторен и стилен интериор,
            който отговаря на всеки стил.
          </li>
        </ul>
      </div>
      <section className="grid grid-cols-2 gap-4 px-4 mt-16 text-lg lg:grid-cols-4 text-fordBlue">
        <div className="p-4 shadow-md">
          <h3 className="py-4 text-center text-white uppercase bg-fordLight">
            Titanium
          </h3>
          <p className="p-4 leading-6 text-center text-fordBlue bg-gray-50">
            За тези, които предпочитат
            <br />
            по-комфортно шофиране
          </p>
          <ul className="pl-4 pr-2 mt-4 list-disc list-outside">
            <li>17" черни алуминиеви джанти</li>
            <li>LED предни фарове и LED дневни светлини, LED задни светлини</li>
            <li>
              12" сензорен екран с подобрена инфоразвлекателна система SYNC 4,
              Apple CarPlay/Android Auto, задни паркинг сензори.
            </li>
          </ul>
        </div>
        <div className="p-4 shadow-md">
          <h3 className="py-4 text-center text-white uppercase bg-fordLight">
            ST-LINE
          </h3>
          <p className="p-4 leading-6 text-center text-fordBlue bg-gray-50">
            За тези, които търсят спортна визия и по-енергично изживяване на
            пътя
          </p>
          <ul className="pl-4 pr-2 mt-4 list-disc list-inside">
            <li>17" алуминиеви джанти ST-Line дизайн</li>
            <li>Спортно окачване за по-динамично управление</li>
            <li>
              Уникален интериор с черна тапицерия на седалките с червени шевове
            </li>
          </ul>
        </div>
        <div className="p-4 shadow-md">
          <h3 className="py-4 text-center text-white uppercase bg-fordLight">
            ST-LINE X
          </h3>
          <p className="p-4 leading-6 text-center text-fordBlue bg-gray-50">
            Комбинира спортен стил с комфорт
            <br /> и първокласни технологии
          </p>
          <ul className="pl-4 pr-2 mt-4 list-disc list-inside">
            <li>18" алуминиеви джанти </li>
            <li>Тапицерия на седалките от кожа/велур с червени шевове</li>
            <li>Премиум аудиосистема B&O с 10 високоговорителя</li>
            <li>Електрическа врата на багажника</li>
          </ul>
        </div>
        <div className="p-4 shadow-md">
          <h3 className="py-4 text-center text-white uppercase bg-fordLight">
            ST
          </h3>
          <p className="py-4 leading-6 text-center text-fordBlue bg-gray-50">
            За търсещите адреналин на пътя
            <br /> и вълнуващо изживяване
          </p>
          <ul className="pl-4 pr-2 mt-4 list-disc list-inside">
            <li>19" алуминиеви джанти</li>
            <li>360° обзор над автомобила</li>
            <li>Адаптивен автопилот</li>
            <li>Отопляеми предни седалки, челно стъкло и волан</li>
          </ul>
        </div>
      </section>
      <h3 className="p-16 text-2xl text-center text-fordLight">
        Puma 2024 се предлага с бензинови EcoBoost двигатели с мека хибридна
        технология с мощност 125 к.с., 155 к.с. и 160 к.с.
        <br />
        Може да избирате между 6-степенна механична или 7-степенна автоматична
        трансмисия.
      </h3>
      <div className="relative mt-4">
        <img
          src="/images/puma2024/2024_FORD_PUMA_6-LOW.jpg"
          alt=""
          className="w-full"
        />
        <div className="absolute bottom-0 w-full px-16 py-12 text-white bg-gradient-to-b from-transparent to-black/80">
          <p className="mt-2 text-2xl">С атрактивни условия за финансиране</p>
        </div>
      </div>
      <section className="grid my-16 lg:grid-cols-2">
        <h3 className="text-4xl text-center lg:text-right text-fordLight">
          Защо да изберете Ford Puma?
        </h3>
        <ul className="pl-16 pr-16 mt-8 list-disc lg:mt-0 lg:pr-48 text-fordBlue">
          <li>
            <span className="font-bold">Интелигентен дизайн и практичност</span>{" "}
            – мултифункционален и удобен автомобил, който пасва на всеки стил на
            живот.
          </li>
          <li className="mt-4">
            <span className="font-bold">Най-високо ниво на безопасност</span> –
            създаден с най-новите технологии за сигурност и безопасност, за да
            гарантира спокойствието ви на пътя.
          </li>
          <li className="mt-4">
            <span className="font-bold">Перфектен за града</span> – компактен,
            но просторен, с възможности за лесно маневриране и паркиране.
          </li>
        </ul>
      </section>
      <section className="flex flex-col items-center px-4 py-12 lg:justify-center lg:p-16 lg:flex-row bg-gray-50">
        <a
          href="https://ford.bg/cars/new-puma"
          target="_blank"
          rel="noreferrer"
        >
          <button className="px-12 py-2 mx-8 border rounded-full border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж повече за модела
          </button>
        </a>
        <a href="https://sale.ford.bg/?model=Puma">
          <button className="px-12 py-2 mx-8 border rounded-full border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж налични автомобили
          </button>
        </a>
        <button
          className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
          onClick={() => {
            setShowModal(true);
            setZapitvane(true);
            window.scrollTo(0, 0);
          }}
        >
          Свържи се с търговец
        </button>
      </section>
      <section className="mt-4">
        <p className="px-4 text-sm text-fordBlue">
          Цената е валидна за ниво на оборудване Titanium, 1.0 EcoBoost mHEV
          двигател, 125 к.с., 6ст. механична скоростна кутия
        </p>
      </section>
      {showModal && zapitvane && (
        <Modal handleClose={handleClose}>
          <GetInTouch model={model} copy={copy} />
        </Modal>
      )}
    </div>
  );
}

export default Puma2024;
